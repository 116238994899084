import React from "react";
import Card from "react-bootstrap/Card";
import { ImPointRight } from "react-icons/im";

function AboutCard() {
  return (
    <Card className="quote-card-view">
      <Card.Body>
        <blockquote className="blockquote mb-0">
          <p style={{ textAlign: "justify" }}>
            Hi Everyone, We are <span className="red">BlisStudio </span>
            from Cirebon, Indonesia.
            <br /> We are expertise branding and social media management. We will help<span className="red"> solve your branding, marketing, and design problems</span>. 
            <br />
            BlisStudio understand that brand monitoring and marketing optimization <span className="red">are high priorities</span> in terms of design fo your business, so take your
            business <span className="red">to the next level</span> and let's grow your audience together.
            <br />
            <br />
            Our Service: 
          </p>
          <ul>
            <li className="about-activity">
              <ImPointRight /> Content Planner
            </li>
            <li className="about-activity">
              <ImPointRight /> Design Post
            </li>
            <li className="about-activity">
              <ImPointRight /> Setup Adds
            </li>
            <li className="about-activity">
              <ImPointRight /> Reels
            </li>
            <li className="about-activity">
              <ImPointRight /> Etc
            </li>
          </ul>

          <p style={{ color: "#c08888" }}>
            "Develop your business with us!"{" "}
          </p>
          <footer className="blockquote-footer">BlisStudio</footer>
        </blockquote>
      </Card.Body>
    </Card>
  );
}

export default AboutCard;
