import React from "react";
import Typewriter from "typewriter-effect";

function Type() {
  return (
    <Typewriter
      options={{
        strings: [
          "Social Media Management Agency",
          "Content Planer",
          "Design Post",
          "Setup Adds",
          "Reels",
          "Develop your business with Us!",
        ],
        autoStart: true,
        loop: true,
        deleteSpeed: 50,
      }}
    />
  );
}

export default Type;
